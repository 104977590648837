<template>
  <section>
    <breadcrumb :title="$t('reports')" :subtitle="`${$t('distributor')}`"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns is-12">
        <div class="column is-2">
          <b-field>
            <multiselect
                :placeholder="$t('distributor')"
                v-model="distributor"
                track-by="id"
                :multiple="true"
                :custom-label="stateName"
                open-direction="bottom"
                :show-no-results="false"
                :close-on-select="false"
                :options="distributorList">
              <template slot="selection" slot-scope="{ values, isOpen }">
                                          <span class="multiselect__single"
                                                v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('dealer_selected') }}
                                          </span>
              </template>
            </multiselect>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <b-datetimepicker
                :placeholder="$t('date_init')"
                v-model="dateInit"
                trap-focus>
            </b-datetimepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <b-datetimepicker
                :placeholder="$t('date_end')"
                v-model="dateEnd"
                trap-focus>
            </b-datetimepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <multiselect
                :placeholder="$t('currency')"
                v-model="currency"
                open-direction="bottom"
                :show-no-results="false"
                :options="currencyList">
            </multiselect>
          </b-field>
        </div>
        <div v-if="false" class="column is-2">
          <b-field>
            <multiselect
                :placeholder="$t('state')"
                v-model="state"
                :custom-label="setNameTransactionStatus"
                open-direction="bottom"
                :show-no-results="false"
                :options="stateList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-2 is-flex is-justify-content-space-between is-align-items-center">
          <div v-if="query!=='' || distributor" @click="getInvoices(1,10)">
            <span class="mdi mdi-magnify"></span>
          </div>
        </div>
        <div class="is-flex is-justify-content-flex-end">
          <button @click="generateReport" class="button new is-pink-light">
            <span class="mdi mdi-export"></span>
            {{ $t('export') }}
          </button>
        </div>
      </div>
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-tabs v-if="desserts.data" :class="{'is-vertical': desserts.data.length > 10}">
        <template v-for="(item, idx) in desserts.data">
          <b-tab-item v-if="item.data.length" :key="idx + 'a'">
            <template #header>
              {{ item.name }}
            </template>
            <b-table
                :data="item.data"
                ref="table"
                :paginated="false"
                :per-page="itemsPerPage"
            >
              <b-table-column field="id" :label="$t('nro')" width="40" numeric v-slot="props">
        <span class="col id">
           #{{ props.row.transactionNumber }}
        </span>
              </b-table-column>

              <b-table-column field="issuer" :label="$t('issuer')" sortable v-slot="props">
            <span class="col details">
              {{ props.row.sender.name }}
            </span>
              </b-table-column>

              <b-table-column field="recipient" :label="$t('recipient')" sortable v-slot="props">
        <span class="col dest">
           {{ props.row.recipent.name }}
        </span>
              </b-table-column>
              <b-table-column field="address" :label="$t('address')" sortable v-slot="props">
        <span class="col dest">
            {{ props.row.recipent.addressLine }},{{ props.row.recipent.province }},{{ props.row.recipent.municipality }}
        </span>
              </b-table-column>
              <b-table-column field="date" :label="$t('date')" sortable v-slot="props">
        <span class="col date">
           {{ formatYYYYMMDD(props.row.date) }}
        </span>
              </b-table-column>
              <b-table-column field="Enviado" :label="$t('amount')" sortable v-slot="props">
        <span class="col cash">
          {{ props.row.amount }} {{ props.row.currency }}
        </span>
              </b-table-column>
            </b-table>
          </b-tab-item>
        </template>
      </b-tabs>
    </section>
    <paginate
        :count="desserts.totalRecords"
        :per_page="desserts.itemsPerPage"
        :current-page="desserts.currentPage"
        :total-pages="desserts.totalPages"
        @first="getLast(true)"
        @previous="getInvoices(desserts.currentPage - 1,desserts.itemsPerPage)"
        @next="getInvoices(desserts.currentPage + 1,desserts.itemsPerPage)"
        @last="getLast"
        @perPage="getInvoices(desserts.currentPage,$event)"
        @search="getInvoices($event,desserts.itemsPerPage)"
    />
    <div>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :manual-pagination="false"
          :pdf-quality="2"
          filename="Shipping Report"
          pdf-content-width="700px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
          :html-to-pdf-options="optionsPdf"
      >
        <section slot="pdf-content">
          <section class="is-flex is-flex-direction-column"
                   v-for="(items, idx) in desserts.data" :key="idx + 'b'"
                   :class="{'html2pdf__page-break':items.data.length>0 && idx>0}"
          >
            <div v-if="items.data.length">
              <div class="is-flex is-justify-content-space-between">
                <div class="is-flex is-align-items-center" v-if="items">
                  <span class="is-bold">{{ $t('report_shipping') }}</span>
                  <span class="ml-4 font-size-10">{{ items.name }} - {{ items.phoneNumber }}</span>
                </div>
                <div class="report-log is-flex is-flex-direction-column" v-if="dateInit && dateEnd">
                  <div class="is-bold ml-4 font-size-10">{{ $t('range_date') }}</div>
                  <div>{{ `${formatYYYYMMDD(dateInit, true)}` }} - {{ `${formatYYYYMMDD(dateEnd, true)}` }}
                  </div>
                </div>
              </div>
              <div class="is-flex is-flex-direction-column mt-20">
                <div class="columns" style="border-bottom: 1px solid #000000 !important;">
                  <div class="column col report head font-size-10">
                    {{ $t('issuer') }}
                  </div>
                  <div class="column  col report head font-size-10">
                    {{ $t('recipient') }}
                  </div>
                  <div class="column col report head font-size-10">
                    {{ $t('remittances') }}
                  </div>
                </div>
                <template v-for="(item, idx) in items.data">
                  <div class="row-report" :class="{'html2pdf__page-break': breakPage(idx)}" :key="idx + 'c'">
                    <div class="columns is-flex" :key="idx + 'd'">
                      <div class="column  col report is-uppercase font-size-10" v-if="item.sender">
                        {{ item.sender.name }}
                      </div>
                      <div class="column col report font-size-10" v-if="item.recipent">
                        <div class="is-flex is-flex-direction-column">
                          <span class="is-uppercase font-size-10"> {{ item.recipent.name }} </span>
                          <span class="font-size-8"> {{ item.recipent.addressLine }},
                          <span class="is-uppercase">{{
                              item.recipent.province
                            }}, {{ item.recipent.municipality }}</span> </span>
                          <span class="is-bold mt-10 font-size-10">{{ $t('phone') }} <span>{{
                              item.recipent.phoneNumber
                            }}</span></span>
                        </div>
                      </div>
                      <div class="column col report">
                        <div class="is-flex is-flex-direction-column">
                          <div class="is-flex is-justify-content-space-between">
                            <span class="font-size-10">#{{ item.transactionNumber }}</span>
                            <span class="font-size-10">{{ formatYYYYMMDD(item.date) }}</span>
                            <span class="font-size-10">{{ item.amount }} {{ item.currency }}</span>

                          </div>
                          <div class="is-flex is-flex-direction-column">
                            <div class="is-flex">
                              <span class="font-size-10">{{ $t('ci') }}</span>
                              <span>____________________</span>
                            </div>
                            <div class="is-flex">
                              <span class="font-size-10">{{ $t('CTRL') }}</span>
                              <span>____________________</span>
                            </div>
                            <div class="is-flex">
                              <span class="font-size-10">{{ $t('firm') }}</span>
                              <span>____________________</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-10 columns is-flex" :key="idx"
                         v-if="breakPage(idx) || idx +1 === items.data.length">
                      <div class="column col report is-uppercase font-size-10" style="width: 20%">
                        <div class="is-flex is-flex-direction-column" v-if="pages.length">
                          <span class="is-uppercase is-bold font-size-10">{{ items.report.province }}</span>
                          <span class="font-size-10" v-if="item && breakPage(idx)">{{
                              $t('total_ship')
                            }} {{ pages[breakPage(idx)].count }}</span>
                          <span class="font-size-10" v-else-if="item && idx +1 === items.data.length">{{
                              $t('total_ship')
                            }}: {{ pages[items.data.length].count }}</span>
                        </div>
                      </div>
                      <div class="column col report" style="width: 60%" v-if="pages.length">
                        <div class="is-flex is-justify-content-space-around" v-if="item && breakPage(idx)">
                        <span class="is-uppercase is-bold font-size-10">{{ $t('total_ship_cup') }}: {{
                            pages[breakPage(idx)].sumCUP
                          }}</span>
                          <span class="is-uppercase is-bold font-size-10">{{ $t('total_ship_usd') }}: {{
                              pages[breakPage(idx)].sumUSD
                            }}</span>
                        </div>
                        <div class="is-flex is-justify-content-space-around"
                             v-else-if="item && idx +1 === items.data.length">
                        <span class="is-uppercase is-bold font-size-10">{{ $t('total_ship_cup') }}: {{
                            pages[items.data.length].sumCUP
                          }}</span>
                          <span class="is-uppercase is-bold font-size-10">{{ $t('total_ship_usd') }}: {{
                              pages[items.data.length].sumUSD
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="columns is-flex is-justify-content-space-between mt-10"
                         v-if="idx +1 === items.data.length">
                      <div class="column font-size-10">{{ $t('summary_upper') }}</div>
                      <div class="column font-size-10">{{ items.data.length }} {{ $t('plural_remittances') }}</div>
                      <div class="column font-size-10" v-if="items.data">{{ $t('total_cup') }}:
                        {{ calcTotals(items.data).sumCUP }}
                      </div>
                      <div class="column font-size-10" v-if="items.data">{{ $t('total_usd') }}:
                        {{ calcTotals(items.data).sumUSD }}
                      </div>
                    </div>
                  </div>

                </template>

              </div>
            </div>

          </section>

        </section>
      </vue-html2pdf>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Multiselect from "vue-multiselect";
import {TRANSACTION_STATUS} from "@/enum/transactionStatus";
import {mapActions, mapGetters} from "vuex";
import Paginate from "@/components/list/paginate";
import logo from "@/assets/remisario/fullcolor/imagotipo.jpg";
import logotipo from "@/assets/img/logotipo.png";
import VueHtml2pdf from 'vue-html2pdf'
import {CURRENCY} from "@/enum/currency";
import {USER_TYPE} from "@/enum/userType";

export default {
  name: "DistributorComponent",
  components: {Paginate, Breadcrumb, Multiselect, VueHtml2pdf},
  data() {
    return {
      pages: [],
      logo, logotipo,
      distributorList: [],
      distributorListSelected: [],
      desserts: [],
      currency: null,
      agency: null,
      dateInit: null,
      dateInit2: null,
      dateEnd: null,
      state: null,
      distributor: null,
      all: false,
      dealerName: '',
      dealerPhone: '',
      query: '',
      dealerSummary: null,
      currencyList: Object.values(CURRENCY),
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      totalCUP: 0,
      totalUSD: 0,
      optionsPdf: {
        margin: [10, 10],
        filename: `Shipping Report`,
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']}
      }
    }
  },
  computed: {
    ...mapGetters({
      stateList: 'GET_STATUS_TRANSACTION',
      user: 'GET_USER',
      lang: 'GET_LANG'
    })
  },
  methods: {
    ...mapActions({
      fetchDealer: 'FETCH_DEALER',
      fetchInvoices: 'FETCH_INVOICE_DEALER',
    }),
    stateName({name}) {
      return name
    },
    onProgress(event) {
      console.log(event);
    },
    hasGenerated(event) {
      console.log(event);
    },
    hasStartedGeneration() {
    },
    calcTotals(list) {
      let sum = {sumCUP: 0, sumUSD: 0}
      list.forEach((item) => {
        if (item.currency === CURRENCY.CUP) {
          sum.sumCUP += item.amount
        }
        if (item.currency === CURRENCY.USD) {
          sum.sumUSD += item.amount
        }
      })
      return sum
    },
    getTotals() {
      return new Promise(resolve => {
        let list = [8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96]
        let sum = {
          sumCUP: 0,
          sumUSD: 0,
          count: 0
        }
        let pages = []
        this.desserts.data.forEach((item, index) => {
          if (item.currency === CURRENCY.CUP) {
            sum.sumCUP += item.amount
          }
          if (item.currency === CURRENCY.USD) {
            sum.sumUSD += item.amount
          }
          sum.count++;
          if (list.find(f => f === index + 1)) {
            pages[list.find(f => f === index + 1)] = sum
            sum = {
              sumCUP: 0,
              sumUSD: 0,
              count: 0
            }
          }
        })
        pages[this.desserts.data.length] = sum
        resolve(pages)
      })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    setNameTransactionStatus({value}) {
      switch (value) {
        case TRANSACTION_STATUS.COMPLETED:
          return this.lang === 'es' ? 'Completado' : 'Completed';
        case TRANSACTION_STATUS.CANCELED:
          return this.lang === 'es' ? 'Cancelado' : 'Canceled';
        case TRANSACTION_STATUS.PENDING:
          return this.lang === 'es' ? 'Pendiente' : 'Pending';
        case TRANSACTION_STATUS.SHIPPING:
          return this.lang === 'es' ? 'Enviando' : 'Shipping';
        case TRANSACTION_STATUS.ERROR:
          return 'Error';

      }
    },
    getDealer(current = 1, record = 1000, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchDealer(data).then(value => {
          resolve(value)
        })
      })
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getInvoices(page, this.desserts.itemsPerPage)
    },
    getInvoices(current = 1, record = 10) {
      if (this.distributorListSelected.length || this.all) {
        let data = {
          currentPage: current,
          recordsPerPage: record,
          query: this.query,
          distributorIds: this.distributorListSelected
        };
        this.fetchInvoices(data)
            .then(value => {
              this.desserts = value
              // this.getTotals()
              //     .then(value => {
              //       this.pages = value
              //       this.totalCUP = 0
              //       this.totalUSD = 0
              //       this.pages.forEach(item => {
              //         this.totalCUP += item.sumCUP
              //         this.totalUSD += item.sumUSD
              //       })
              //     })
              this.all = false
            })
      }

    },
    formatYYYYMMDD(value, hour = false) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;

        if (hour) {
          return year + '-' +
              ((month < 10 ? '0' : '') + month) + '-' +
              ((day < 10 ? '0' : '') + day) + ' ' + `${hours}:${minutes}:${seconds} ${ampm}`
        }
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    formatHHMMSS(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        return this.formatNumber(hours, true) + ':' +
            this.formatNumber(minutes, true) + ':' +
            this.formatNumber(seconds, true)
      }
      return ''
    },
    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      let ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      return `${hours}:${minutes}:${seconds} ${ampm}`
    },
    breakPage(idx) {
      let list = [8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96]
      return list.find(f => f === idx + 1)
    }
  },
  watch: {
    dateInit: function () {
      if (this.dateInit) {
        this.query += `&initDate=${this.formatYYYYMMDD(this.dateInit)}`
      }
    },
    dateEnd: function () {
      if (this.dateEnd) {
        this.query += `&finalDate=${this.formatYYYYMMDD(this.dateEnd)}`
      }
    },
    agency: function () {
      if (this.agency) {
        this.query += `&agencyId=${this.agency.id}`
      }
    },
    state: function () {
      if (this.state) {
        this.query += `&status=${this.state.value}`
      }
    },
    currency: function () {
      if (this.currency) {
        this.query += `&currency=${this.currency}`
      }
    },
    distributor: function () {
      if (this.distributor[0].id === 0) {
        this.distributorListSelected = []
        this.all = true
      } else {
        this.distributorListSelected = []
        this.distributor.forEach(item => {
          this.distributorListSelected.push(item.id)
        })
      }
      console.log(this.distributorListSelected);
    }
  },
  created() {
    if (this.user.userType !== USER_TYPE.DEALER && this.user.userType !== USER_TYPE.DELIVERYMAN) {
      this.getDealer()
          .then(value => {
            let name = this.lang === 'es' ? 'Seleccionar todo' : 'Select All'
            this.distributorList.push({name: name, id: 0})
            value.data.forEach(item => {
              this.distributorList.push(item)
            })
          })
    }
    if (this.user.userType === USER_TYPE.DEALER || this.user.userType === USER_TYPE.DELIVERYMAN) {
      this.distributor = this.user
      this.distributorList.push(this.user)
      this.distributorListSelected.push(this.user.id)
      this.getInvoices(1, 10)
    }

  }
}
</script>

<style scoped lang="scss">

.row-report {
  padding-bottom: 10px;
  border-bottom: 1px solid;
  line-height: 12px;

}

.col {
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

  &.report {
    line-height: 20px;
  }

  &.id {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #C1C1C1;
  }

  &.details {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.manager {
    font-weight: bold;
    font-size: 16px;
    color: #4D5EC3;
  }

  &.dest {
    font-weight: normal;
    font-size: 16px;
  }

  &.agency {
    font-weight: bold;
    font-size: 16px;
    color: #28BCE8;
  }

  &.agent {
    font-weight: bold;
    font-size: 16px;
    color: #0B2B46;
  }

  &.date {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &.cash {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
  }
}

.hr-divider {
  height: 1px;
  background: #AEAEAE;
}

.total-balance {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}

.button {
  &.new {
    width: 150px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-export {
    font-size: 38px;
  }
}

.mdi-magnify {
  font-size: 38px;
}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.pending {
    color: #289BA4;
  }

}

.tag:not(body).is-rounded {
  border-radius: 290486px;
  min-width: 125px;
}

.table-scroll {
  overflow-y: auto;
  max-height: 45vh;
}
</style>
